* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
}

.checkout-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
}



.checkout-main {
  flex: 1;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.checkout-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: center;
}

.checkout-form {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.form-control {
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-control:focus {
  outline: none;
  border-color: #2196f3;
  box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.2);
}

.form-control::placeholder {
  color: #666;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-group label {
  font-weight: 500;
  color: #333;
}

.form-group input {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.form-group input:focus {
  outline: none;
  border-color: #1a365d;
  box-shadow: 0 0 0 2px rgba(26, 54, 93, 0.1);
}

.form-notice {
  color: #666;
  font-size: 0.9rem;
  line-height: 1.4;
  padding: 0.5rem 0;
}

.submit-button {
  margin-top: 1rem;
  padding: 0.75rem 2rem;
  background-color: #1A365D;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #2a466d;
}

.checkout-intro {
  text-align: center;
  margin-bottom: 2rem;
}

.checkout-intro h1 {
  color: #1a365d;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.checkout-intro p {
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 600px;
  margin: 0 auto;
} 
