.checkout-header {
  padding: 0.15rem;
  background-color: #1A365D;
  position: relative;
  left: calc(-50vw + 50%);
  width: 100vw;
}

.header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-start;
}

.header-content img {
  height: auto;
  max-width: 100%;
  width: auto;
  max-height: 40px;
}

@media screen and (max-width: 768px) {
  .header-content {
    justify-content: center;
    padding: 0 0.5rem;
  }
}
