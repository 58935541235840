.link-button {
  margin-top: 1rem;
  padding: 0.75rem 2rem;
  background-color: #1A365D;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  align-self: center;
  transition: background-color 0.2s;
}

.link-button:hover {
  background-color: #2a466d;
}